import $axios from 'axios';

export const findPermissionApi = (roleId) => {
    return $axios({
        method: 'GET',
        url: `/permission/findPermission/${roleId}`,
        headers: {
            Authorization: sessionStorage.getItem('token'),
            CompanyId: sessionStorage.getItem('companyId'),
            RoleId: sessionStorage.getItem('roleId'),
        }
    })
}

export const updatePermissionApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'PUT',
        url: "/permission/updatePermission",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}
<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="" v-for="(role,index) in roleList" :key="role['roleId']" style="margin-left: 20%; margin-right: 20%;">
            <a-button type="primary" size="large" ghost block @click="findPermission(role['roleId'], index)">
              <span v-if="isShow === index" style="color: rebeccapurple; font-size: 200%; font-family: 'STXingkai', serif;">{{ role.roleName }}</span>
              <span v-else>{{ role.roleName }}</span>
            </a-button>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-tree :showLine="true"
                  v-model:expandedKeys="expandedKeys"
                  v-model:checkedKeys="checkedKeys"
                  checkable
                  :tree-data="treeData"
                  @check="checkTitle"
          >
          </a-tree>
        </a-col>
      </a-row>
    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import { message } from 'ant-design-vue';

import * as RoleApi from '@/api/PoleApi.js';
import * as PermissionApi from '@/api/PermissionApi.js';

export default {
  name: 'RolePermission',
  components: {
    MainPanel
  },
  data() {
    return {

      roleName: '',

      roleList: [],

      treeData: [],

      expandedKeys: [],
      checkedKeys: [],

      roleId: '',

      isShow: '',

    }
  },

  methods: {
    findRole() {
      RoleApi['findRoleApi'](`roleName=${this.roleName}`)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 1) {
            this.roleList = result.data.data;
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },


    findPermission(roleId, index) {
      // console.log(roleId)
      this.isShow = index;
      this.roleId = roleId;
      PermissionApi['findPermissionApi'](roleId)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 1) {
            this.treeData = result.data.data['treeData'];
            this.expandedKeys = result.data.data['expandedKeys'];
            this.checkedKeys = result.data.data['checkedKeys'];
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

    checkTitle(checkedKeys) {
      PermissionApi['updatePermissionApi']({permissionIdList: checkedKeys, roleId: this.roleId})
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 1) {
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })

    }

  },

  created() {
    this.findRole()
  },

  mounted() {

  }
}
</script>
